import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import {
  AngularPlugin,
  AngularPluginService,
} from '../external/applicationinsights-angularplugin-js/applicationinsights-angularplugin-js';
import { LoggerApiService } from '../shared/services/api/logger/logger-api.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

import { ApplicationService } from '../shared/services/application/application.service';
import { LogData } from './log-data';
import { AppointmentService } from '../shared/services/appointment/appointment.service';
import { UserProfileService } from '../shared/services/user/user-profile.service.service';
import { versionNo } from '../version/version-number';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  public ErrorsList: BehaviorSubject<any> = new BehaviorSubject<Error[]>([]);
  appInsights: ApplicationInsights;
  applicationId: number;
  listOfIds: LogData;

  constructor(
    private router: Router,
    private angularPluginService: AngularPluginService,
    private loggerApiService: LoggerApiService,
    private applicationService: ApplicationService,
    private appointmentService: AppointmentService,
    private userProfileService: UserProfileService
  ) {
    const angularPlugin = new AngularPlugin();
    this.angularPluginService.init(angularPlugin, this.router);
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.instrumentationKey,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router },
        },
      },
    });
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();
  }

  convertBehaviourSubjectToObservable() {}

  logPageView(name?: string, uri?: string) {
    // option to call manually
    this.appInsights.trackPageView({ name, uri });
  }

  async logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name }, properties);
    const log = {};

    log['level'] = 2;
    log['template'] = name;
    log['Parameters'] = properties;

    // Due to API code was commented
    // this.loggerApiService.logMessage(log).subscribe((resp) => {
    //   // console.log(resp);
    // });
  }

  logUserLogin(name: string) {
    this.logEvent('Security User Login : {name} ', [name, new Date()]);
  }

  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any }
  ) {
    this.appInsights.trackMetric({ name, average }, properties);
  }

  createMessage(exception) {
    const { applicationId, appointmentId, resourceId } = this.listOfIds;
    const message = exception.message
      ? exception.message
      : exception.stack
      ? exception.stack
      : JSON.stringify(exception);
    return `${message} ${
      applicationId ? `applicationId :${applicationId}` : ''
    }  ${appointmentId ? `appointmentId :${appointmentId}` : ''} ${
      resourceId ? `resourceId :${resourceId}` : ''
    } `;
  }
  logException(exception: Error, logData: LogData = undefined) {
    this.listOfIds = logData || new LogData(
      this.applicationService,
      this.appointmentService,
      this.userProfileService
    );

    const { applicationId, appointmentId, resourceId, userName } =
      this.listOfIds;
    const properties = {
      applicationId,
      resourceId,
      appointmentId,
      userName,
      payload: exception['payload'],
      url: exception['url'],
      apiError: exception['apiError'],
      buildVersion: versionNo,
    };
    this.appInsights.trackException({
      exception,
      properties,
    });
    const logMessage = {};
    logMessage['level'] = 5;
    // logMessage['template'] = '{Message} {Application Id}';
    logMessage['template'] = this.createMessage(exception);
    logMessage['Parameters'] = [this.createMessage(exception)];
    this.ErrorsList.next([exception]);
    // do not disable the line below, otherwise we will never get console errors.
    console.log(exception.message);
    // Due to API code was commented
    // this.loggerApiService.logMessage(logMessage).subscribe((resp) => {});
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message }, properties);
  }
}
