<div [innerHtml]="GetText()"></div>
<div class="row justify-content-end">
  <div>
    <button
      class="btn btn-outline-secondary btn-md mb-3 mr-2"
      (click)="open(content)"
    >
      <img
        src="./assets/images/ushg/edit.svg"
        alt="image"
        width="20"
        height="20"
        title="Edit"
      />
    </button>
    <button
      class="btn btn-outline-secondary btn-md mb-3 mr-2"
      (click)="copyDetails()"
      *ngIf="details.PayorSelectorTag === 'Payment.ApplicationPayorInitial'"
    >
      <img
        src="./assets/images/ushg/copy.svg"
        alt="image"
        width="20"
        height="20"
        title="Copy from Recurring Payment"
      />
    </button>
    <button
      *ngIf="details.PayorSelectorTag === 'Payment.ApplicationPayorInitial'"
      class="btn btn-outline-secondary btn-md mb-3 mr-2"
      (click)="resetDetails()"
    >
      <img
        src="./assets/images/ushg/reset.svg"
        alt="image"
        width="20"
        height="20"
        title="Reset to EzApp values"
      />
    </button>
  </div>
</div>
<div>
  <app-verifiable-list
    [model]="verifiableList"
    *ngIf="GetModel() && showList"
    [isCustomEdit]="true"
    (formComplete)="verificationComplete($event)"
  >
  </app-verifiable-list>

  <div
    *ngIf="displayEmailNotification && question.instructions"
    class="col-sm-12 alert alert-warning text-center mt-3"
    role="alert"
  >
    {{ question.instructions }}
  </div>
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        Add/Edit Billing Details
      </h4>

      <button
        type="button"
        class="close outline-0"
        aria-label="Close"
        (click)="modal.dismiss('Cross click'); closeModal(f)"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form name="form" #f="ngForm">
        <div
          class="btn-group d-flex justify-content-end"
          role="group"
          *ngIf="details.PayorSelectorTag === 'Payment.ApplicationPayorInitial'"
        >
          <div class="pr-2">
            <button
              class="btn btn-outline-secondary btn-md mb-3 mr-2"
              (click)="copyDetailsPopup()"
              *ngIf="
                details.PayorSelectorTag === 'Payment.ApplicationPayorInitial'
              "
            >
              <img
                src="./assets/images/ushg/copy.svg"
                alt="image"
                width="20"
                height="20"
                title="Copy from Recurring Payment"
              />
            </button>
          </div>
        </div>
        <div class="form-group">
          <label for="billingName"
            >Billing Name <span class="text-danger">*</span></label
          >
          <div class="input-group">
            <input
              id="billingName"
              class="form-control"
              name="billingName"
              required
              type="text"
              [(ngModel)]="editPayment.name"
              #billingName="ngModel"
            />
          </div>
          <div
            *ngIf="
              billingName.invalid &&
              (billingName.dirty || billingName.touched || saveClicked)
            "
            class="mt-1 text-danger small"
          >
            <div *ngIf="billingName.errors.required">
              Billing Name is required.
            </div>
          </div>
        </div>
        <div
          *ngIf="
            this.payorType === 'PayorIsSomeoneElseAndOffline' ||
            this.payorType === 'PayorIsSomeoneElseAndOnline'
          "
        >
          <div class="mb-2">
            {{ this.details.popUpPromptText }}
          </div>
          <div class="form-group">
            <label for="businessName"
              >Person/Business Name <span class="text-danger">*</span></label
            >
            <div class="input-group">
              <input
                id="businessName"
                class="form-control"
                name="businessName"
                required
                type="text"
                [(ngModel)]="editPayment.businessName"
                #businessName="ngModel"
              />
            </div>
            <div
              *ngIf="
                businessName.invalid &&
                (businessName.dirty || businessName.touched || saveClicked)
              "
              class="mt-1 text-danger small"
            >
              <div *ngIf="businessName.errors.required">
                Person/Business Name is required.
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="businessOwner">Business Owner/Manager</label>
            <div class="input-group">
              <input
                id="businessOwner"
                class="form-control"
                name="businessOwner"
                type="text"
                [(ngModel)]="editPayment.businessOwner"
                #businessOwner="ngModel"
              />
            </div>
            <div
              *ngIf="
                businessOwner.invalid &&
                (businessOwner.dirty || businessOwner.touched || saveClicked)
              "
              class="mt-1 text-danger small"
            >
              <div *ngIf="businessOwner.errors.required">
                Business Owner/Manager is required.
              </div>
            </div>
          </div>
          <!-- <div class="form-group">
            <label for="email"
              >Email
              <span class="text-danger" *ngIf="isEmailRequired">*</span></label
            >
            <div class="input-group">
              <input
                id="email"
                class="form-control"
                name="email"
                type="email"
                [(ngModel)]="editPayment.email"
                #email="ngModel"
                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                [required]="isEmailRequired"
              />
            </div>
            <div
              *ngIf="
                email.invalid && (email.dirty || email.touched || saveClicked)
              "
              class="mt-1 text-danger small"
            >
              Please enter valid Email.
            </div>
          </div> -->
        </div>

        <div
          class="form-group"
          *ngIf="this.question.type === 'PayorInformation'"
        >
          <label for="phone"> Phone <span class="text-danger">*</span></label>
          <div class="input-group">
            <input
              id="phone"
              pattern="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
              type="tel"
              name="phone"
              [(ngModel)]="editPayment.phone"
              #phone="ngModel"
              class="form-control"
              required
              placeholder="Phone"
              mask="(000) 000-0000"
              [showMaskTyped]="true"
              [dropSpecialCharacters]="false"
            />
          </div>
          <div
            *ngIf="
              phone.invalid && (phone.dirty || phone.touched || saveClicked)
            "
            class="mt-1 text-danger small"
          >
            <div *ngIf="phone.errors.required">Phone is required.</div>
            <div *ngIf="phone.errors.pattern">
              Invalid phone format (XXX) XXX-XXXX.
            </div>
          </div>
        </div>
        <app-usps-address
          *ngIf="this.question.type === 'PayorInformation'"
          (formComplete)="OnformCompleted($event)"
          (formValidity)="OnAddressFormValidity($event)"
          [address]="{
            streetAddress: editPayment.streetAddress,
            city: editPayment.city,
            state: editPayment.state,
            zipCode: editPayment.zipCode
          }"
          [saveClicked]="saveClicked"
        ></app-usps-address>
        <div *ngIf="isAddressInValid" class="alert alert-warning small">
          Address is invalid, please make sure you entered correct Street
          Address, Zip, City and State. However, you can still continue to
          proceed.
        </div>
        <div class="position-relative">
          <ngx-spinner
            bdOpacity="0.9"
            bdColor="#f4f4f9"
            size="default"
            color="#000000"
            type="ball-spin-clockwise"
            [fullScreen]="false"
          >
          </ngx-spinner>
        </div>
        <div
          class="form-group"
          *ngIf="this.payorType === 'PayorIsSomeoneElseAndOffline'"
        >
          <label for="notes"
            >Notes (Ex: Best time to call, availability.... etc )</label
          >
          <div class="input-group">
            <textarea
              id="notes"
              class="form-control"
              name="notes"
              required
              type="text"
              [(ngModel)]="editPayment.notes"
              #notes="ngModel"
              placeholder="Notes"
            ></textarea>
          </div>
          <div
            *ngIf="
              notes.invalid && (notes.dirty || notes.touched || saveClicked)
            "
            class="mt-1 text-danger small"
          >
            <div *ngIf="notes.errors.required">Notes is required.</div>
          </div>
        </div>
        <div *ngIf="this.payorType === 'PayorIsSomeoneElseAndOnline'">
          <div class="mb-2"><b>Authorized Signer Details</b></div>
          <div class="form-group">
            <label for="authorizedName"
              >Name <span class="text-danger">*</span></label
            >
            <div class="input-group">
              <input
                type="text"
                id="authorizedName"
                class="form-control"
                name="authorizedName"
                required
                [(ngModel)]="editPayment.authorizedName"
                #authorizedName="ngModel"
              />
            </div>
            <div
              *ngIf="
                authorizedName.invalid &&
                (authorizedName.dirty || authorizedName.touched || saveClicked)
              "
              class="mt-1 text-danger small"
            >
              <div *ngIf="authorizedName.errors.required">
                Authorized Name is required.
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="authorizedPhone"
              >Phone <span class="text-danger">*</span></label
            >
            <div class="input-group">
              <input
                pattern="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
                type="tel"
                id="authorizedPhone"
                class="form-control"
                name="authorizedPhone"
                required
                [(ngModel)]="editPayment.authorizedPhone"
                #authorizedPhone="ngModel"
                placeholder="Authorized Phone"
                mask="(000) 000-0000"
                [showMaskTyped]="true"
                [dropSpecialCharacters]="false"
              />
            </div>
            <div
              *ngIf="
                authorizedPhone.invalid &&
                (authorizedPhone.dirty ||
                  authorizedPhone.touched ||
                  saveClicked)
              "
              class="mt-1 text-danger small"
            >
              <div *ngIf="authorizedPhone.errors.required">
                Authorized Phone is required.
              </div>
              <div *ngIf="authorizedPhone.errors.pattern">
                Invalid phone format (XXX) XXX-XXXX.
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer justify-content-between">
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="modal.dismiss('Cancel'); closeModal(f)"
      >
        Cancel
      </button>
      <button type="submit" class="btn btn-primary" (click)="Save(f)">
        Save
      </button>
    </div>
  </ng-template>
</div>
