import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Section, SectionStatus } from 'src/app/shared/models/vcall/section';
import {
  Application,
  OtherCoverage,
} from 'src/app/shared/models/application/application';
import { CallZoneUser } from 'src/app/shared/models/callzone-user';
import { Question } from 'src/app/shared/models/vcall/question';
import { Answer } from 'src/app/shared/models/application/answer';
import { MedicalItem } from 'src/app/shared/models/medicalItem';
import {
  IndemnityCoverage,
  PolicyInformation,
  Product,
} from 'src/app/shared/models/application/product';
import { Applicant } from 'src/app/shared/models/application/applicant';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';
import { Script } from 'src/app/shared/models/vcall/script';
import { ApplicationSearchResult } from 'src/app/admin/completed-vcalls/completed-vcalls.component';
import { Subscription } from 'rxjs/internal/Subscription';
import {
  ScriptService,
  VcallMode,
} from 'src/app/shared/services/script/script.service';
import {
  ApplicationService,
  CacheListItem,
} from 'src/app/shared/services/application/application.service';
import { Appointment } from 'src/app/shared/models/appointment';
import { VcallParams } from 'src/app/shared/models/vcall/vcallParams';
import { NgxSpinnerService } from 'ngx-spinner';
import { RecallInfo } from 'src/app/shared/models/recall/RecallInfo';

@Component({
  selector: 'app-print-view',
  templateUrl: './print-view.component.html',
  styleUrls: ['./print-view.component.scss'],
})
export class PrintViewComponent implements OnInit, OnDestroy {
  criticalQuestions: Question[] = new Array<Question>();
  subscriptions = Array<Subscription>();
  @Input() printViewData: PrintViewData;
  ailmentsArray: MedicalItem[];
  // occupationDetailAnswerArray = new Array< OccupationDetailsAnswer>();
  indemnityCoverage: Array<IndemnityCoverage>;
  product: Product;
  otherCoverage: OtherCoverage[];
  policyInformation: Array<PolicyInformation> = [];
  family: Array<Applicant>;
  primaryApplicant: Applicant;
  questions = new Array<Question>();
  selectedApplicant = new Applicant();
  agent: CallZoneUser;
  appointment: Appointment;
  sections: Section[];
  selectedCall: any;
  application: Application;
  item = [];
  answers: Answer[];
  detailAnswers = new Array<DetailAnswers>();
  completedCallList = new ApplicationSearchResult();
  activeSection = 0;
  completedStatus: Answer;
  notes: string;
  thirdPartyDetailsMonthly = new ThirdPartyDetails();
  thirdPartyDetailsInitial = new ThirdPartyDetails();
  modeArray: Array<string>;
  exclusions: Array<string>;
  appointments: Appointment[];
  removedQuestions: Question[] = [
    {
      id: 12115,
      text: 'Does anyone listed on the application have a full time occupation, meaning you work over 30 hrs per week?',
      displayWhen: null,
      instructions: null,
      value: null,
      type: 'MedicalQualifying',
      answerTag: 'Demographic.WorkHours',
      sequence: 10,
      allowNextForValue: null,
      required: true,
      details: {},
      payment: null,
      answer: null,
      section: null,
      isFirst: null,
      canGoNext: null,
      endCallPopupText: null,
      promptText: null,
      sectionName: 'Demographic',
    },
    {
      id: 12116,
      text: 'Ok, How many hours/months for <span class="font-weight-bold">{{selectedApplicant.fullName | titleCase}}</span> .',
      displayWhen: null,
      instructions: null,
      value: null,
      type: 'ApplicantQualifiedTextBox',
      answerTag: 'Demographic.WorkHours',
      sequence: 11,
      allowNextForValue: null,
      required: true,
      details: {
        answerTag: 'FullTimeOccupation',
      },
      payment: null,
      answer: null,
      section: null,
      isFirst: null,
      canGoNext: null,
      endCallPopupText: null,
      promptText: null,
      sectionName: 'Demographic',
    },
  ];
  criticalSection = {
    name: 'Critical',
    description: 'Critical',
    sequence: null,
    status: null,
    totalQuestions: null,
    questionsDone: null,
    toggle: false,
  };
  customPaymentAnswerDisplayTags: string[] = [
    'payment.methodOfPayment',
    'payment.initialPaymentBy',
  ];
  cacheListItemInstance: CacheListItem = Object.create(null);

  constructor(
    private uiService: UiServiceService,
    private scriptService: ScriptService,
    private applicationService: ApplicationService,
    private SpinnerService: NgxSpinnerService
  ) {}

  async ngOnInit() {
    this.SpinnerService.show();
    const options = new VcallParams();
    options.applicationId = this.printViewData.applicationId;
    options.mode = VcallMode.Standard;
    options.stopNavigation = 'true';
    await this.applicationService.SelectApplication(options.applicationId);
    this.scriptService.LoadScript(options);
    this.subscriptions.push(
      this.applicationService.SelectedApplication.subscribe((a: any) => {
        this.application = a;
        this.family = this.application.family;
        this.primaryApplicant = this.application.primaryApplicant;
        this.indemnityCoverage = this.application.indemnityCoverage;
        this.otherCoverage = this.application.otherCoverage;
        this.policyInformation = this.application.policyInformation;
        if (this.indemnityCoverage) {
          this.indemnityCoverage = this.indemnityCoverage.filter(
            (v, i, ele) => ele.findIndex((t) => t.planName === v.planName) === i
          );
        }

        if (this.otherCoverage) {
          this.otherCoverage = this.otherCoverage.filter(
            (v, i, ele) =>
              ele.findIndex((t) => t.otherPlanName === v.otherPlanName) === i
          );
        }
      })
    );
    this.subscriptions.push(
      this.scriptService.Sections.subscribe((sections) => {
        this.sections = sections;
        if (this.sections) {
          this.sections.push(this.criticalSection);
        }
      })
    );
    const search = {
      applicationId: Number(this.printViewData.applicationId),
      skip: 0,
      take: 1,
    };
    this.subscriptions.push(
      this.applicationService
        .SearchApplication(search)
        .then((data: ApplicationSearchResult[]) => {
          this.completedCallList = data[0];
        })
    );

    this.subscriptions.push(
      this.scriptService.ApplicationScript.subscribe((script) => {
        if (script && script.items !== null) {
          this.SpinnerService.hide();
          this.questions = [];
          script.items.forEach((s) => {
            // backward compatibility for removed the questions
            switch (s.section.name) {
              case 'Introduction':
                s = this.checkForRemovedQuetions(
                  s,
                  this.filterQuestionsBySectionName(s.section.name)
                );
                break;
              case 'Payment':
                s = this.checkForRemovedQuetions(
                  s,
                  this.filterQuestionsBySectionName(s.section.name)
                );
                break;
              case 'Demographic':
                s = this.checkForRemovedQuetions(
                  s,
                  this.filterQuestionsBySectionName(s.section.name)
                );
                break;
              case 'Medical':
                s = this.checkForRemovedQuetions(
                  s,
                  this.filterQuestionsBySectionName(s.section.name)
                );
                break;
              case 'Medical History':
                s = this.checkForRemovedQuetions(
                  s,
                  this.filterQuestionsBySectionName(s.section.name)
                );
                break;
              case 'Closing':
                s = this.checkForRemovedQuetions(
                  s,
                  this.filterQuestionsBySectionName(s.section.name)
                );
                break;
            }
            this.item.push(s);
            s.questions.forEach((q) => {
              if (q !== undefined) {
                this.questions.push(q);
              }
            });
            this.criticalQuestions = [];
            this.questions.forEach((question) => {
              if (question.details?.Critical === 'true') {
                this.criticalQuestions.push(question);
              }
            });
          });
        }
      })
    );
    this.subscriptions.push(
      this.applicationService.Answers.subscribe((a) => {
        if (a && a.length > 0) {
          this.answers = a;
          const appointments: Appointment[] = this.answers.find(
            (i) => i.answerTag === 'appointment'
          )?.value;
          this.agent = new CallZoneUser();
          if (appointments && appointments.length > 0) {
            this.appointments = appointments;
            this.agent.name =
              appointments[0].callzoneVerifier === null ||
              appointments[0].callzoneVerifier === undefined
                ? ''
                : appointments[0].callzoneVerifier;
          }
          this.processCompletedCallStatus();
          this.getThirdPartyPayorDetails();
        } else {
          this.answers = [];
        }
      })
    );
  }

  // check for removed quetions
  checkForRemovedQuetions(scriptQuestions, removedQuestions) {
    removedQuestions.forEach((removedQuestion) => {
      const index = scriptQuestions.questions.findIndex(
        (q) => q.text === removedQuestion.text
      );
      if (index === -1) {
        // lint error was fixed here
        scriptQuestions.questions.push(removedQuestion);
      }
    });
    return scriptQuestions;
  }

  // filetr questions by setcion wise
  filterQuestionsBySectionName(sectionName) {
    return this.removedQuestions.filter((q) => q.sectionName === sectionName);
  }

  private filterExclusion(modeText) {
    switch (modeText) {
      case 'policyInformation':
        this.exclusions.forEach((e) => {
          this.policyInformation = this.policyInformation.filter((p) => {
            return p.product !== e;
          });
        });
        break;
    }
  }

  getPolicyInformation(question) {
    if (question.details.mode) {
      this.modeArray = question.details.mode.split(',');
    }
    if (question.details.exclusions) {
      this.exclusions = question.details.exclusions.split(',');
    }
    if (this.exclusions && this.exclusions.length > 0) {
      this.modeArray.forEach((m) => {
        this.filterExclusion(m);
      });
    }
    return this.policyInformation;
  }
  processCompletedCallStatus() {
    const answer: Answer[] = this.answers.filter(
      (a) => a.answerTag === 'AppointmentProgress'
    );
    if (answer.length > 0) {
      this.completedStatus = answer.filter((a) => {
        return (
          a.value.status === 'complete' &&
          a.value.callType !== RecallInfo.callType
        );
      })[0];
    }
  }
  safeInterpolate(question: Question) {
    const stringToInterpolate = question.text;
    let result = stringToInterpolate;

    this.selectedApplicant.firstName = '';
    this.selectedApplicant.lastName = '';

    const scope = {
      application: this.application,
      agent: this.agent,
      SelectedApplicant: this.selectedApplicant,
      selectedApplicant: this.selectedApplicant,
    };

    // console.log(question);
    try {
      if (this.answers && this.answers.length > 0) {
        result = this.uiService.interpolate(
          stringToInterpolate,
          scope,
          null,
          question
        );
      }
    } finally {
    }
    return result;
  }
  getNameForClientNo(clientNo: string) {
    return this.application.applicants.find((a) => a.clientNo === clientNo)
      .fullName;
  }
  getVerification(tag: string) {
    const answer: Answer = this.answers.find((a) => a.answerTag === tag);
    return answer ? answer.verification : false;
  }
  public navigateToSection(section: string, index: number) {
    this.activeSection = index;
    window.location.hash = '';
    window.location.hash = section;
  }
  getDetailAnswerForQuestion(question: Question) {
    return this.detailAnswers.filter((d) => {
      return d.answertag === question.answerTag;
    });
  }
  getPromptforQuestion(question: Question): any {
    const prompt: Answer = this.answers.find(
      (a) => a.answerTag === question.answerTag
    );
    return prompt ? prompt.prompt : this.safeInterpolate(question);
  }
  getThirdPartyPayorDetails() {
    if (
      this.getAnswerforTag('Payment.ApplicationPayorMonthly')?.value ===
      'PayorIsSomeoneElseAndOnline'
    ) {
      this.thirdPartyDetailsMonthly.businessName =
        this.application.payment.businessNameMonthlyOnline;
      this.thirdPartyDetailsMonthly.businessOwner =
        this.application.payment.businessOwnerMonthlyOnline;
      this.thirdPartyDetailsMonthly.authorizedName =
        this.application.payment.authorizedNameMonthly;
      this.thirdPartyDetailsMonthly.authorizedPhone =
        this.application.payment.authorizedPhoneMonthly;
    } else if (
      this.getAnswerforTag('Payment.ApplicationPayorMonthly')?.value ===
      'PayorIsSomeoneElseAndOffline'
    ) {
      this.thirdPartyDetailsMonthly.businessName =
        this.application.payment.businessNameMonthlyOffline;
      this.thirdPartyDetailsMonthly.businessOwner =
        this.application.payment.businessOwnerMonthlyOffline;
      this.thirdPartyDetailsMonthly.notes =
        this.application.payment.notesMonthlyOffline;
    }
    if (
      this.getAnswerforTag('Payment.ApplicationPayorInitial')?.value ===
      'PayorIsSomeoneElseAndOnline'
    ) {
      this.thirdPartyDetailsInitial.businessName =
        this.application.payment.businessNameInitialOnline;
      this.thirdPartyDetailsInitial.businessOwner =
        this.application.payment.businessOwnerInitialOnline;
      this.thirdPartyDetailsInitial.authorizedName =
        this.application.payment.authorizedNameInitial;
      this.thirdPartyDetailsInitial.authorizedPhone =
        this.application.payment.authorizedPhoneInitial;
    } else if (
      this.getAnswerforTag('Payment.ApplicationPayorInitial')?.value ===
      'PayorIsSomeoneElseAndOffline'
    ) {
      this.thirdPartyDetailsInitial.businessName =
        this.application.payment.businessNameInitialOffline;
      this.thirdPartyDetailsInitial.businessOwner =
        this.application.payment.businessOwnerInitialOffline;
      this.thirdPartyDetailsInitial.notes =
        this.application.payment.notesInitialOffline;
    }
    switch (this.getAnswerforTag('payment.methodOfPayment')?.value) {
      case 'BANK DRAFT':
        this.thirdPartyDetailsMonthly.billingName =
          this.application.payment.nameOnCheck;
        this.thirdPartyDetailsMonthly.streetAddress =
          this.application.payment.addressOnCheck;
        this.thirdPartyDetailsMonthly.city =
          this.application.payment.cityOnCheck;
        this.thirdPartyDetailsMonthly.state =
          this.application.payment.stateOnCheck;
        this.thirdPartyDetailsMonthly.zipCode =
          this.application.payment.zipOnCheck;
        this.thirdPartyDetailsMonthly.phone =
          this.application.payment.phoneOnCheck;
        break;
      case 'DIRECT BILLING':
        this.thirdPartyDetailsMonthly.billingName =
          this.application.payment.billingName;
        this.thirdPartyDetailsMonthly.streetAddress =
          this.application.payment.billingAddress;
        this.thirdPartyDetailsMonthly.city =
          this.application.payment.billingCity;
        this.thirdPartyDetailsMonthly.state =
          this.application.payment.billingState;
        this.thirdPartyDetailsMonthly.zipCode =
          this.application.payment.billingZip;
        this.thirdPartyDetailsMonthly.phone =
          this.application.payment.billingPhone;
        break;
    }
    switch (this.getAnswerforTag('payment.initialPaymentBy')?.value) {
      case 'BANK DRAFT':
        this.thirdPartyDetailsInitial.billingName =
          this.application.payment.initPmtBankNameOnCheck;
        this.thirdPartyDetailsInitial.streetAddress =
          this.application.payment.initPmtBankAddressOnCheck;
        this.thirdPartyDetailsInitial.city =
          this.application.payment.initPmtBankCityOnCheck;
        this.thirdPartyDetailsInitial.state =
          this.application.payment.initPmtBankStateOnCheck;
        this.thirdPartyDetailsInitial.zipCode =
          this.application.payment.initPmtBankZipOnCheck;
        this.thirdPartyDetailsInitial.phone =
          this.application.payment.initPmtBankPhoneOnCheck;
        break;
      case 'CREDIT CARD':
        this.thirdPartyDetailsInitial.billingName =
          this.application.payment.initPmtBillingName;
        this.thirdPartyDetailsInitial.streetAddress =
          this.application.payment.initPmtBillingAddress;
        this.thirdPartyDetailsInitial.city =
          this.application.payment.initPmtBillingCity;
        this.thirdPartyDetailsInitial.state =
          this.application.payment.initPmtBillingState;
        this.thirdPartyDetailsInitial.zipCode =
          this.application.payment.initPmtBillingZip;
        this.thirdPartyDetailsInitial.phone =
          this.application.payment.iniPmtCCBillingPhone;
        break;
    }
  }
  getAnswerforTag(answertag) {
    return this.answers.filter((a) => {
      return a.answerTag === answertag;
    })[0];
  }

  getQuestionInstanceAnswered(question: Question): any {
    const answer: Answer = this.answers.find(
      (a) => a.answerTag === question.answerTag
    );
    if (answer) {
      return (
        question.id === answer.questionId ||
        answer.questionId === null ||
        answer.questionId === undefined
      );
    }
  }

  private getAnswerByTag(question) {
    if (this.customPaymentAnswerDisplayTags.includes(question.answerTag)) {
      const initialRecurringAnswer: Answer = this.answers.find(
        (a) => a.answerTag === question.answerTag
      );
      if (initialRecurringAnswer && !!initialRecurringAnswer.ezAppValue) {
        initialRecurringAnswer.value === initialRecurringAnswer.ezAppValue
          ? (initialRecurringAnswer.value = 'Yes')
          : initialRecurringAnswer.value.includes('Yes')
          ? initialRecurringAnswer.value
          : (initialRecurringAnswer.value =
              'No - ' + initialRecurringAnswer.value.replace('No - ', ''));
      } else {
        initialRecurringAnswer.value = 'Yes';
      }
      return initialRecurringAnswer;
    }
  }

  getAnswerforQuestion(question: Question): any {
    this.detailAnswers = [];
    const detailAnswer = new DetailAnswers();

    let answer: Answer = this.answers.find(
      (a) => a.answerTag === question.answerTag
    );

    // This changes is for display the initialPaymentBy answer
    this.getAnswerByTag(question);

    if (answer) {
      this.notes = answer.notes;
    }
    if (!answer) {
      return '';
    }

    if (
      answer &&
      Array.isArray(answer.value) &&
      answer.value.filter((v) => v.applicable).length === 0
    ) {
      switch (question.type) {
        case 'MedicalQualifying':
          return 'None';
        default: {
          return '';
        }
      }
    }

    if (answer.value === 'true') {
      return 'Yes';
    } else if (answer.value === 'false') {
      return 'No';
    } else if (Array.isArray(answer.value)) {
      detailAnswer.answertag = answer.answerTag;
      detailAnswer.values = [];
      detailAnswer.details = [];
      detailAnswer.ailments = [];
      detailAnswer.medicalQualifyingQuestionApplicants = [];
      answer.value.forEach((v) => {
        if (v.hasOwnProperty('values')) {
          detailAnswer.values.push({
            clientNumber: v.clientNo,
            value: v.values,
          });
        } else if (
          !v.hasOwnProperty('values') &&
          question.type === 'QualifyingStudent'
        ) {
          detailAnswer.values.push({
            clientNumber: v.clientNo,
            value: [v],
          });
        }
        // if applicant name select for 'MedicalQualifying' questions to display the applicant name
        if (
          v.hasOwnProperty('value') &&
          question.type === 'MedicalQualifying' &&
          v.applicable === true
        ) {
          detailAnswer.medicalQualifyingQuestionApplicants.push({
            clientNumber: v.clientNo,
            value: v.value,
          });
        }
        if (v.hasOwnProperty('ailments')) {
          v.ailments.forEach((a) => {
            detailAnswer.ailments.push({ clientNumber: v.clientNo, value: a });
          });
        }
        if (v.hasOwnProperty('details')) {
          let medication;
          let doctorLookup;
          v.details.forEach((d) => {
            d.clientNumber = v.clientNo;
            medication = d.answers.find((a) => {
              return Array.isArray(a.value);
            });
            doctorLookup = d.answers.find((a) => {
              return a.answerTag === 'DoctorNameAddress';
            });
          });

          if (doctorLookup?.value.length > 0) {
            doctorLookup.value = doctorLookup.value[0];
          }

          detailAnswer.details.push(v.details);
        }
      });
      if (
        detailAnswer &&
        detailAnswer.details &&
        detailAnswer.details.length > 0
      ) {
        detailAnswer.details = [].concat(...detailAnswer.details);
      }
      const applicableClientNos = answer.value.filter((a) => {
        return a.applicable;
      });

      detailAnswer.clientNames = applicableClientNos.map((a) => {
        if (a.ailments) {
          return (
            this.getNameForClientNo(a.clientNo) + ' - ' + a.ailments.join(', ')
          );
        } else if (a.values) {
          return this.getNameForClientNo(a.clientNo);
        }
      });

      this.detailAnswers.push(detailAnswer);
    } else {
      return answer.value;
    }
  }

  highlightToRed(question) {
    let matchFound = false;
    this.criticalQuestions.forEach((item) => {
      if (item.answerTag === question.answerTag) {
        matchFound = true;
      }
    });
    return matchFound;
  }

  ngOnDestroy() {
    this.subscriptions.map((s) => {
      if (s && s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.applicationService.list = this.cacheListItemInstance;
  }
}
export class PrintViewData {
  applicationId: string;
}
export class DetailAnswers {
  ailments: ValuesList[];
  values: ValuesList[];
  details: any;
  clientNumber: string;
  answertag: string;
  clientNames: Array<string>;
  medicalQualifyingQuestionApplicants: ValuesList[];
}
export class ValuesList {
  clientNumber: string;
  value: any;
}
export class ThirdPartyDetails {
  billingName: string;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  phone: string;
  businessName: string;
  businessOwner: string;
  notes: string;
  authorizedName: string;
  authorizedPhone: string;
}
