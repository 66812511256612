import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Answer, CosmosAnswer } from '../../models/application/answer';
import { Applicant } from '../../models/application/applicant';
import { Application } from '../../models/application/application';
import { DiscoverableChainItem } from '../../models/application/discoverableChainItem';
import { VCallApplicant } from '../../models/vcall/vcallApplicants';
import { VcallApiService } from '../api/vcall/vcall-api.service';
import { ModelMapperService } from './modelMapperService.service';
import {
  VerifiableModel,
  StringToEncrypt,
} from 'src/app/vcall/questions/types/verifiableList/verifiable-list.component';
import { ApplicationNote, CallLog } from '../../models/vcall/applicationNote';
import { MedicalItem } from '../../models/medicalItem';
import { Appointment } from '../../models/appointment';
import { DetailQuestion } from '../../models/vcall/detailQuestion';
import { EncryptedValues } from '../../models/vcall/encryptedValues';
import { Recall } from '../../models/recall/Recall';
import { ApplicationSearchResult } from 'src/app/admin/completed-vcalls/completed-vcalls.component';
import { RxProfileService } from '../rx-profile.service';
import { Question } from '../../models/vcall/question';
import * as moment from 'moment';
import { Notification } from '../../interfaces/Notification';
import { environment } from 'src/environments/environment';
import { EFullfillmentService } from '../e-fullfillment/e-fullfillment.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UiServiceService } from '../ui-service.service';
import { DemographicUpdateResponseItem } from '../processors/processing-result';
import { LoggerService } from 'src/app/core/logger.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  readonly APPOINTMENT_PROGRESS_ANSWERTAG = 'AppointmentProgress';
  enableNotificationFeature = environment.features.sms;
  private OnlineApplicantsAnswerTag = 'onlineApplicants';
  private AppointmentAnswerTag = 'appointment';
  private currentlySaving = '';
  private lastSaved = '';
  public list: CacheListItem = Object.create(null);
  private onlineApplicants: VCallApplicant[] = new Array<VCallApplicant>();
  public selectedApplication: Application;
  private dashboardAnswers: Answer[] = new Array<Answer>();
  private originalApplication: Application;
  public SelectedApplication: BehaviorSubject<Application> =
    new BehaviorSubject<Application>(Application.DefaultInstance());
  public ezAppApplication: BehaviorSubject<Application> =
    new BehaviorSubject<Application>(Application.DefaultInstance());
  public OnlineApplicants: BehaviorSubject<VCallApplicant[]> =
    new BehaviorSubject<VCallApplicant[]>(this.onlineApplicants);

  public Notes: BehaviorSubject<ApplicationNote[]> = new BehaviorSubject<
    ApplicationNote[]
  >(null);
  public CallLogs: BehaviorSubject<CallLog[]> = new BehaviorSubject<CallLog[]>(
    null
  );
  public Notifications: BehaviorSubject<Notification[]> = new BehaviorSubject<
    Notification[]
  >(null);
  public DashboardAnswers: BehaviorSubject<Answer[]> = new BehaviorSubject<
    Answer[]
  >(this.dashboardAnswers);

  private selectedApplicant: Applicant;
  public SelectedApplicant: BehaviorSubject<Applicant> =
    new BehaviorSubject<Applicant>(Applicant.DefaultInstance());

  public ApplicationAppointment: BehaviorSubject<Appointment[]> =
    new BehaviorSubject<Appointment[]>(
      new Array(Appointment.DefaultInstance())
    );
  public Answers: BehaviorSubject<Answer[]> = new BehaviorSubject<Answer[]>([]);
  public DetailQuestions: BehaviorSubject<DetailQuestion[]> =
    new BehaviorSubject<DetailQuestion[]>(null);
  public ApplicationRecalls: BehaviorSubject<Recall[]> = new BehaviorSubject<
    Recall[]
  >(null);
  public notesText: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public notesList = {};

  public currentApplicationId: number = 0;
  private activeApplicationId: string | null = null;
  public importApplicationModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private api: VcallApiService,
    private mapper: ModelMapperService,
    private rxProfileService: RxProfileService,
    private efulfillmentService: EFullfillmentService,
    private spinnerService: NgxSpinnerService,
    private uiService: UiServiceService,
    private loggerService: LoggerService
  ) {
    let self = this;
    self.ezAppApplication.subscribe((resp) => {
      if (resp) self.currentApplicationId = resp.id;
    });
  }

  private selectedApplicationItem: ApplicationCacheItem;

  /**
   * @param applicationId pass the selected applicationId
   * @description get the selected application information
   */
  public async SelectApplication(applicationId: string) {
    let self = this;
    let attempt = 0;
    if (
      self.list[applicationId] !== undefined &&
      self.list[applicationId] !== null &&
      self.list[applicationId].answers !== null &&
      self.list[applicationId].answers.length
    ) {
      // Found in Cache

      const cache = self.list[applicationId];
      self.selectedApplicationItem = cache;
      // REVISIT to restore the freeze
      // Object.freeze(application);
      self.selectedApplication = cache.application;
      self.originalApplication = cache.originalApplication;
      // this.SelectedApplication.next(cache.application);
      // Process Answers will call SelectedApplication.next
      self.ProcessAnswers(
        applicationId,
        cache.answers,
        self.selectedApplication,
        true
      );

      // Notes
      self.Notes.next(cache.notes);

      // CallLogs
      self.CallLogs.next(cache.callLogs);
      self.Notifications.next(cache.notifications);

      // Recalls
      self.ApplicationRecalls.next(cache.recalls);
    } else {
      // Not Found in Cache

      /*
      KPACA NOTE: Removing this for now since its causing jarring user experience with verifiers
      self.spinnerService.show();
    */

      // Clean
      // KPACA NOTE: This has been commented out because its causing refresh every 30 seconds
      // this only happens on applications that dont have answers. the logic above checks for answers.
      // self.Clear(false);

      // TODO: Reconsider answers into its own service.
      self.list[applicationId] = new ApplicationCacheItem();

      await self.getOrImportApplication(applicationId, attempt);
    }
  }


  private async getOrImportApplication(applicationId: string, count) {
    let self = this;
    self.activeApplicationId = applicationId;

    try {
        const app = await self.api.GetApplication(applicationId).toPromise();
        
        if (app !== null && app.status !== 400) {
            // If application is found, process it
            await self.getApplication(applicationId, app);
            return;
        } else {
            // If the application is not found, clear and import
            self.Clear(false);
            count++;

            if (count < 2) {
              self.api.importApplication(applicationId).subscribe({
                next: () => {
                  // Retry fetching the application after 10 seconds
                  setTimeout(() => {
                    if (self.activeApplicationId === applicationId) {
                        self.getOrImportApplication(applicationId, count + 1);
                    }
                  }, 10000);
                },
                error: (err) => {
                    console.error("Import application failed:", err);
                }
              })
            } else {
              self.importApplicationModal.next(true);
            }
        }
    } catch (error) {
        console.error(`Error occurred:`, error);
    }
  }

  // Method to process a successfully retrieved application
  private async getApplication(applicationId: string, app: any) {
    let self = this;

    const efulfillment = await self.efulfillmentService
      .GetEfulfillment(applicationId)
      .toPromise();

    app.contact.eFulfillment = efulfillment;

    let promise1 = await self.api
      .GetApplication(applicationId)
      .toPromise()
      .then((a) => {
        a.contact.eFulfillment = efulfillment;
        self.originalApplication = Application.Copy(a);
        self.ezAppApplication.next(self.originalApplication);
        if (typeof self.list === 'object' && self.list !== null) {
          self.list[applicationId].originalApplication =
            self.originalApplication;
        }
      });

    let promise2 = await self.api
      .GetAnswers<ApiAnswer>(applicationId)
      .toPromise()
      .then((apiAnswer) => {
        let answers = null;
        if (apiAnswer === null || apiAnswer.answers === undefined) {
          answers = new Array<Answer>();
        } else {
          if (typeof self.list === 'object' && self.list !== null) {
            self.list[applicationId].etagAnswers = apiAnswer.etag;
          }

          answers = apiAnswer.answers.map((a) => new Answer(a));
        }
        // Object.assign(application, app);
        self.selectedApplication = Application.Copy(app);
        self.ProcessAnswers(
          applicationId,
          answers,
          self.selectedApplication,
          true
        );

        const storedAppointments = self.GetApplicationAppointments();
        if (storedAppointments != null) {
          self.ApplicationAppointment.next(storedAppointments);
        }
        if (storedAppointments && storedAppointments.length > 0) {
          const appointmentId = storedAppointments[0].appointmentId;
          const applicants = self.GetAnswerForAnswerTag(
            self.OnlineApplicantsAnswerTag + '-' + appointmentId
          );

          if (applicants && applicants.value) {
            self.OnlineApplicants.next(applicants.value);
          }
        }
      });

    await Promise.all([promise1, promise2]).then(() => {
      /*
                          KPACA NOTE: Removing this for now since its causing jarring user experience with verifiers
                          self.spinnerService.hide();
                        */
    });

    // notes
    self.GetNotes(applicationId);
    // fetch call logs
    self.GetCallLog(applicationId);
    // fetch Recalls
    self.LoadRecall(applicationId);
    // fetch Notifications
    if (self.enableNotificationFeature) {
      self.loadNotifications(applicationId);
    }
  }

  /**
   * @description get the selected application entities
   */
  public GetSelectedApplicationEntities(): ApplicationCacheItem {
    return this.list[this.selectedApplication.id];
  }

  /**
   * @description add the online applicants
   */
  public AddOnlineApplicants(applicants: Applicant[], appointmentId: number) {
    // TODO: Adding undefined check caused by reusing the component
    // interest of time adding data check here,
    // better fix is that the callee should provide good data.
    const items = applicants
      .filter((a) => a !== undefined)
      .map((a) => new VCallApplicant(a));
    this.RegisterAnswerByTag(
      true,
      items,
      this.GetOnlineApplicantAnswerTagByAppointmentId(appointmentId)
    );
    this.OnlineApplicants.next(items);
  }

  /**
   * @description get online applicants
   */
  public GetOnlineApplicants(appointmentId: number) {
    return this.GetAnswerForAnswerTag(
      this.GetOnlineApplicantAnswerTagByAppointmentId(appointmentId)
    );
  }

  /**
   * @param appointmentId selected appointmentId
   * @description get online applicant by answertag
   */
  private GetOnlineApplicantAnswerTagByAppointmentId(appointmentId: number) {
    return this.OnlineApplicantsAnswerTag + '-' + appointmentId;
  }

  /**
   * @param appointment selected appointment
   * @description add the appointment
   */
  public AddAppointment(appointment: Appointment) {
    let list = [];
    const answer = this.GetAnswerForAnswerTag(this.AppointmentAnswerTag);

    if (answer) {
      list = answer.value;
    }
    // Removing existing appointment
    list.forEach((a, index) => {
      if (a.appointmentId === appointment.appointmentId) {
        list.splice(index, 1);
      }
    });
    list.push(appointment);
    this.RegisterAnswerByTag(true, list, this.AppointmentAnswerTag);
  }

  /**
   * @description get application appointment
   */
  public GetApplicationAppointments(): any {
    const answer = this.GetAnswerForAnswerTag(this.AppointmentAnswerTag);
    if (answer) {
      return answer.value;
    } else {
      return null;
    }
  }

  /**
   * @description register answers by queston related tag
   */
  public RegisterAnswersByTag(answers: VerifiableModel<any>[]) {
    answers.map((a) => {
      // very kludgy, needs TLC
      const answer = new Answer();
      let valueChanged = false;

      if (a.changedValue) {
        answer.value = a.changedValue;
        valueChanged = true;
      }

      answer.answerTag = a.answerTag;
      answer.verification = a.verification;

      const existing = this.GetAnswerForAnswerTags(
        answer.answerTag,
        answer.clientNo
      );
      if (existing) {
        if (valueChanged) {
          existing.value = answer.value;
        }
        existing.verification = answer.verification;
        this.ProcessEzAppAnswer(existing);
      } else {
        this.ProcessEzAppAnswer(answer);
        this.list[this.selectedApplication.id].answers.push(answer);
      }
    });

    this.ProcessAnswers(
      this.selectedApplication.id.toString(),
      this.list[this.selectedApplication.id].answers,
      this.selectedApplication,
      false
    );
  }

  updateAnswerModel(response: [DemographicUpdateResponseItem]) {
    let self = this;
    let appointmentId;
    const storedAppointments = self.GetApplicationAppointments();
    if (storedAppointments != null) {
      self.ApplicationAppointment.next(storedAppointments);
    }
    if (storedAppointments && storedAppointments.length > 0) {
      appointmentId = storedAppointments[0].appointmentId;
    }

    response.forEach((data: DemographicUpdateResponseItem) => {
      const existing = self.GetAnswerForAnswerTags(
        data.answerTag,
        data.clientNumber,
        appointmentId
      );

      if (!existing || existing === null) {
        self.loggerService.logException(
          new Error(
            `Cannot find AnswerTag: ${data.answerTag} | ClientNumber: ${data.clientNumber}`
          )
        );
      } else {
        existing.as400Status = data.aS400Status;
      }
    });

    self.ProcessAnswers(
      this.selectedApplication.id.toString(),
      this.list[this.selectedApplication.id]?.answers,
      this.selectedApplication,
      false
    );
  }

  // Use Case : Property without deep links
  public RegisterAnswerByTag(
    verification: boolean,
    value: any,
    tag: string,
    applicants: string[] = null,
    clientNo: string = null,
    prompt: string = null,
    type: string = null,
    appointmentId: number = null,
    ignored?: boolean,
    questionId: number = null
  ) {
    this.IsAnswerValueConformsToType(value, type);

    const answer = new Answer();

    answer.value = value;
    answer.prompt = prompt;
    answer.answerTag = tag;
    answer.propertyType = type;
    answer.verification = verification;
    answer.clientNo = clientNo;
    answer.appointmentId = appointmentId;
    answer.notes = this.notesList[answer.answerTag]
      ? this.notesList[answer.answerTag]
      : null;
    answer.ignored = ignored;
    answer.questionId = questionId;
    // TODO: Need to remove this
    if (applicants !== null) {
      answer.applicants = applicants;
    }

    const existing = this.GetAnswerForAnswerTags(
      answer.answerTag,
      answer.clientNo,
      answer.appointmentId
    );

    if (existing) {
      this.IsAnswerValueConformsToType(value, existing.propertyType);

      existing.value = answer.value;
      existing.verification = answer.verification;
      existing.notes = answer.notes;
      existing.ignored = answer.ignored;
      existing.questionId = answer.questionId;
      this.ProcessEzAppAnswer(existing);
    } else {
      // This should happen for the first time ever
      this.ProcessEzAppAnswer(answer);
      this.list[this.selectedApplication.id]?.answers.push(answer);
    }

    this.ProcessAnswers(
      this.selectedApplication.id.toString(),
      this.list[this.selectedApplication.id]?.answers,
      this.selectedApplication,
      false
    );
  }

  // Copy the question answer to another question
  public isQuestionGenericMode() {
    const isGenericMode = this.GetAnswerForAnswerTag(
      'Payment.SamePaymentTypeConfirmation'
    );
    return isGenericMode && isGenericMode.value === 'true' ? true : false;
  }

  public getQuestionText(question: Question) {
    // If Question is in genericMode select the question text
    if (this.isQuestionGenericMode()) {
      if (question.details['textExpressPaymentMode'] !== undefined) {
        return question.details['textExpressPaymentMode'];
      }
    } else {
      return question.details['text'];
    }
  }

  /**
   * @param question current  question
   * @param notes current question notes
   * @description update the notes
   */
  updateNotes(question: Question, notes: string) {
    const existingAnswer = this.GetAnswerForAnswerTags(question.answerTag);
    this.notesList[question.answerTag] = notes;
    this.notesText.next(notes);
    if (existingAnswer) {
      existingAnswer.notes = notes;
    }
  }

  /**
   * @param answer current  question answer
   * @description process the EzApp answers
   */
  private ProcessEzAppAnswer(answer: Answer) {
    // if ezApp answer is already established by any upstream component don't bother.
    if (answer.ezAppValue === null || answer.ezAppValue === undefined) {
      // if chain is absent which should not happen
      if (answer.answerTag === null || answer.answerTag === undefined) {
        console.log('Not mapping ezapp value');
        return;
      }

      const originalValue = this.GetOriginalValueForChain(
        answer.answerTag,
        answer.clientNo
      );
      // originalValue is undefined for non application attribute properties.

      if (originalValue !== undefined) {
        answer.propertyType = 'Application';

        if (answer.value && originalValue !== answer.value) {
          answer.ezAppValue = originalValue;
        } else {
          if (answer.ezAppValue) {
            delete answer.ezAppValue;
          }
        }
      }
    }
  }

  public async PersistAnswers(showResponseMessage = true): Promise<boolean> {
    const apiAnswer = new ApiAnswer();
    apiAnswer.id = this.selectedApplication.id;
    apiAnswer.state = this.selectedApplication.state;
    apiAnswer.answers = this.list[this.selectedApplication.id].answers.map(
      (a) => new CosmosAnswer(a)
    );
    const toSave = JSON.stringify(apiAnswer);
    let callback;
    let reject;
    const promise = new Promise<boolean>((r, e) => {
      callback = r;
      reject = e;
    });

    // See if anything changed while ignoreing the AppointmentProgress
    if (!this.HasAnswersChanged(apiAnswer)) {
      return Promise.resolve(true);
    }

    // Find out the modified answer nodes including the AppointmentProgress
    // lastSaved is the in-memory peristed node

    // TODO:// I dont like this but quick fix
    if (this.lastSaved === '') {
      const defaultLastSaved = new ApiAnswer();
      defaultLastSaved.id = 0;
      defaultLastSaved.state = '';
      defaultLastSaved.answers = [];
      this.lastSaved = JSON.stringify(defaultLastSaved);
    }

    // Model that was successfully persisted.
    var lastSavedNodes: ApiAnswer = JSON.parse(this.lastSaved);
    // Store all the modified nodes.
    var changedNodes: CosmosAnswer[] = [];
    var deletedTags: string[] = [];

    this.currentlySaving = toSave;

    apiAnswer.answers.forEach((a) => {
      var lastNodeVersion = lastSavedNodes.answers.find(
        (n) => n.answerTag === a.answerTag
      );
      // New Node or It was changed
      if (
        lastNodeVersion === undefined ||
        JSON.stringify(lastNodeVersion) != JSON.stringify(a)
      ) {
        changedNodes.push(a);
      }
    });

    lastSavedNodes.answers.forEach((l) => {
      const found = apiAnswer.answers.find((a) => a.answerTag === l.answerTag);
      if (!found) {
        deletedTags.push(l.answerTag);
      }
    });

    // ### Moving this logic to backend
    /*
    // To support simultaneous changes of the data
    // Get the latest version of the answer from the server.
    let serverAnswer = await this.api
      .GetAnswers<ApiAnswer>(this.selectedApplication.id.toString())
      .toPromise();
    if (serverAnswer) {
      // For all the changed nodes
      // superimpose all the changed nodes to the Server version of the answer model.
      // insert new nodes and update existing nodes.
      // This will not delete nodes.
      changedNodes.forEach((n) => {
        const sa = serverAnswer.answers.find(
          (s) => s.answerTag === n.answerTag
        );
        if (sa) {
          sa.value = n.value;
          sa.verification = n.verification;
          sa.notes = n.notes;
          sa.ignored = n.ignored;
          sa.ezAppValue = n.ezAppValue;
          sa.questionId = n.questionId;
          sa.clientNo = n.clientNo;
          sa.applicants = n.applicants;
          sa.appointmentId = n.appointmentId;
          sa.prompt = n.prompt;
          sa.propertyType = n.propertyType;
          sa.questionId = n.questionId;
        } else {
          serverAnswer.answers.push(n);
        }
      });
    } else {
      serverAnswer = apiAnswer;
    }

    deletedTags.forEach((d) => {
      const extraNodeInServerIndex = serverAnswer.answers.findIndex(
        (a) => a.answerTag === d
      );
      if (extraNodeInServerIndex !== -1) {
        serverAnswer.answers.splice(extraNodeInServerIndex, 1);
      }
    });

    console.log('Changed Nodes ->', changedNodes);
    console.log('Deleted Nodes ->', deletedTags);
    */

    // STEP 3 : Save Answers
    console.log('Saving answers');
    const changedApiAnswer = new ApiAnswer();
    changedApiAnswer.id = this.selectedApplication.id;
    changedApiAnswer.state = this.selectedApplication.state;
    changedApiAnswer.answers = changedNodes;
    this.api
      .SaveAnswers<ApiAnswer>(
        changedApiAnswer,
        changedApiAnswer.id,
        deletedTags,
        showResponseMessage,
        this.list[this.selectedApplication.id].etagAnswers
      )
      .subscribe(
        (r) => {
          this.lastSaved = this.currentlySaving;
          callback(true);
        },
        (e: ApiAnswer) => {
          // comes here for etag error
          this.currentlySaving = this.lastSaved;
          callback(false);
        }
      );

    return promise;
  }

  /**
   * @param apiAnswer passing answers as a parameter
   * @description Checking wheather answer is changed or not
   */
  public HasAnswersChanged(apiAnswer): boolean {
    const removeInprogressNodeFromToSave = apiAnswer.answers.filter(
      (f: Answer) => f.answerTag !== this.APPOINTMENT_PROGRESS_ANSWERTAG
    );
    const removeInprogressNodeFromAnswers =
      this.lastSaved !== ''
        ? JSON.parse(this.lastSaved).answers.filter(
            (f: Answer) => f.answerTag !== this.APPOINTMENT_PROGRESS_ANSWERTAG
          )
        : this.lastSaved;
    return (
      JSON.stringify(removeInprogressNodeFromToSave) !==
      JSON.stringify(removeInprogressNodeFromAnswers)
    );
  }

  /**
   * @param answerTag current  question answer tag
   * @param clientNo client number
   * @param appointmentId appointment Id
   * @description get answers of the current question
   */
  // Property without deep links
  // Stand alone questions which are not backed by Application property will be using this API
  public GetAnswerForAnswerTags(
    answerTag: string,
    clientNo?: string,
    appointmentId?: number
  ): Answer {
    if (
      this.selectedApplication &&
      this.list[this.selectedApplication.id] !== undefined &&
      this.list[this.selectedApplication.id].answers !== undefined
    ) {
      const answer = this.list[this.selectedApplication.id].answers.filter(
        (a) =>
          a.answerTag === answerTag &&
          (!a.clientNo || !clientNo || a.clientNo === clientNo) &&
          (!a.appointmentId ||
            !appointmentId ||
            a.appointmentId === appointmentId)
      );
      return answer ? answer[0] : null;
    } else {
      return null;
    }
  }

  /**
   * @param tag current  question tag
   * @param clientNo client number
   * @description value of current question answer tag
   */
  public GetValueForAnswerTag(tag: string, clientNo?: string) {
    //    FindValueForAnswerTag
    if (tag === null || tag === undefined) {
      return null;
    }
    const chain = new Array<DiscoverableChainItem>();
    tag.split('.').map((i) => chain.push({ tag: i }));
    return this.mapper.FindValueForAnswerTag(
      this.selectedApplication,
      tag,
      clientNo
    );
  }

  /**
   * @param answerTag current  question answer tag
   * @param clientNo client number
   * @description original value for chain of questions
   */
  public GetOriginalValueForChain(answerTag: string, clientNo: string) {
    //    FindValueForAnswerTag
    if (!answerTag) {
      return null;
    }

    return this.mapper.FindValueForAnswerTag(
      this.originalApplication,
      answerTag,
      clientNo
    );
  }

  /**
   * @param tag current  question tag
   * @description get answer for answer tag
   * @returns answer for answer tag
   */
  public GetAnswerForAnswerTag(tag: string): Answer {
    if (tag === null || tag === undefined) {
      return null;
    }
    const tags = new Array<DiscoverableChainItem>();
    tag.split('.').map((i) => tags.push({ tag: i }));
    return this.GetAnswerForAnswerTags(tag);
  }

  public GetDashboardAnswers(dashboardType: string) {
    const answers = this.list[this.selectedApplication.id].answers.filter(
      (ans: Answer) => {
        if (
          ans.answerTag.split('.')[0] === dashboardType &&
          ans.value &&
          Array.isArray(ans.value)
        ) {
          return ans.value.filter((element: MedicalItem) => {
            return element.ailments !== undefined && element.applicable;
          });
        }
      }
    );
    this.DashboardAnswers.next(answers);
  }
  public GetDashboardAnswersForDrugs() {
    const answers = this.list[this.selectedApplication.id].answers.filter(
      (ans: Answer) => {
        // return ans.value.filter((element: MedicalItem) => {
        return ans.answerTag === 'MedicalHistory.PrescriptionDrugs';
        // });
      }
    );
    this.DashboardAnswers.next(answers);
  }

  /**
   * @param questionAnswerTags question answer tag
   * @description section answers
   * @returns return the answers
   */
  public GetSectionAnswers(questionAnswerTags: Array<string>) {
    const answers = [];
    this.list[this.selectedApplication.id].answers.forEach((ans) => {
      questionAnswerTags.forEach((a) => {
        if (a === ans.answerTag) {
          answers.push(ans);
        }
      });
    });
    return answers;
  }

  /**
   * @param aswers answers
   * @description delete question answer
   */
  public DeleteQuestionAnswers(answers: Answer[]) {
    console.log('Answers Deleted -> ', answers);
    answers.forEach((s) => {
      this.list[this.selectedApplication.id].answers.forEach(
        (ans: Answer, index) => {
          if (ans.answerTag === s.answerTag) {
            this.list[this.selectedApplication.id].answers.splice(index, 1);
          }
        }
      );
    });
    this.PersistAnswers();
  }

  public DeleteAnswerForAnswerTag(answerTag: string | string[]) {
    console.log('Answers Deleted -> ', answerTag);
    if (Array.isArray(answerTag)) {
      answerTag.forEach((s) => {
        this.list[this.selectedApplication.id].answers.forEach((a, index) => {
          if (s === a.answerTag) {
            this.list[this.selectedApplication.id].answers.splice(index, 1);
          }
        });
      });
    } else {
      this.list[this.selectedApplication.id].answers.forEach((a, index) => {
        if (a.answerTag === answerTag) {
          this.list[this.selectedApplication.id].answers.splice(index, 1);
        }
      });
    }
    this.selectedApplication = Application.Reset(
      this.selectedApplication,
      this.originalApplication
    );
    this.ProcessAnswers(
      this.selectedApplication.id.toString(),
      this.list[this.selectedApplication.id].answers,
      this.selectedApplication,
      false
    );
  }

  /**
   * @param QQTag question question as string
   * @param AQTag answer question tag
   * @description details of the question
   */
  public GetDetailQuestions(QQTag: string, AQTag: string) {
    console.log(QQTag, AQTag);
    const scriptVersion = this.getScriptVersionFromAnswer();
    const appId = this.selectedApplication.id.toString();
    this.api
      .GetDetailQuestions(appId, QQTag, AQTag, true, scriptVersion)
      .subscribe((detailQuestions) => {
        Object.freeze(detailQuestions);
        console.log(detailQuestions);
        this.DetailQuestions.next(detailQuestions);
      });
  }

  public getScriptVersionFromAnswer() {
    const answers =
      this.list && this.selectedApplication && this.selectedApplication.id !== 0
        ? this.list[this.selectedApplication.id].answers
        : [];
    const scriptValue =
      Array.isArray(answers) && answers.length
        ? answers.find(
            (item: Answer) =>
              item.answerTag.toLowerCase() === 'Script.Version'.toLowerCase()
          )
        : null;
    return scriptValue ? scriptValue.value : null;
  }

  // private isArrayValueSame(
  //   tag1 : string[],
  //   tag2 : string[],
  //   clientNo? : string
  // ) {
  //   let result = array1 && array1.length > 0 && array1.length === array2.length;
  //   if (
  //     array2[0].tag === 'OtherApplicants' &&
  //     array1[0].tag === 'OtherApplicants'
  //   ) {
  //     if (array2[1].id !== array1[1].id && array2[1].tag === array1[1].tag) {
  //       result = false;
  //     }
  //   } else if (array1[0].tag === 'family' && array2[0].tag === 'family') {
  //     if (array2[1].id !== array1[1].id && array2[1].tag === array1[1].tag) {
  //       result = false;
  //     }
  //   }

  //   for (let i = 0; i < array1.length; i++) {
  //     result = result && array1[i].tag === array2[i].tag;
  //   }

  //   return result;
  // }

  /**
   * @param applicationId application Id
   * @param answers answers
   * @param application application Data
   * @description process answers
   */
  private ProcessAnswers(
    applicationId: string,
    answers: Answer[],
    application: Application,
    buildChain: boolean = false
  ) {
    // this.GetRxProfile(application);
    if (answers === undefined || answers === null) {
      answers = [];
    }
    // Answers Handling
    // Perform the mapping with the Answers.
    if (this.list[applicationId] && this.list[applicationId].answers) {
      this.list[applicationId].answers = answers;
    }
    // if (buildChain) {
    //   // Build Chain for Registering Answer
    //   this.mapper.BuildChain(application);
    // }
    // Applying Saved Answers
    answers.map((a) => this.mapper.Map(application, a));
    // Application handling
    if (this.list[applicationId] && this.list[applicationId].application) {
      this.list[applicationId].application = application;
    }

    // REVISIT restore the freeze.
    // Object.freeze(application);

    this.selectedApplication = application;
    this.SelectedApplication.next(application);
    this.Answers.next(answers);
  }

  /**
   * @param applicationId application Id
   * @description fetch the current question note
   */
  private GetNotes(applicationId: string) {
    this.api.GetNotes(applicationId).subscribe((apiNotes) => {
      Object.freeze(apiNotes);
      if (typeof this.list === 'object' && this.list !== null) {
        this.list[applicationId].notes = apiNotes;
      }

      this.Notes.next(apiNotes);
    });
  }

  private GetRxProfile(application: Application) {
    if (application && application.applicants.length > 0) {
      application.applicants.forEach((a) => {
        if (
          a.relationship &&
          (a.isRxAuthorized === undefined || a.isRxAuthorized === null)
        ) {
          this.rxProfileService
            .GetRxProfile(application.id.toString(), a.relationship)
            .subscribe((rxProfile) => {
              a.isRxAuthorized = rxProfile;
            });
        }
        this.SelectedApplication.next(application);
      });
    }
  }

  /**
   * @param note application note
   * @description save the notes
   */
  public SaveNote(note: ApplicationNote) {
    return new Promise((resolve, reject) => {
      this.api.SaveNote(note).subscribe(
        (apiNote) => {
          this.GetNotes(note.id.toString());
          resolve(null);
        },
        (error) => reject(error)
      );
    });
  }

  /**
   * @param note selected note
   * @description update the selected note
   */
  public UpdateNote(note: ApplicationNote) {
    return new Promise((resolve, reject) => {
      this.api.UpdateNote(note).subscribe(
        (apiNote) => {
          this.GetNotes(note.id.toString());
          resolve(null);
        },
        (error) => reject(error)
      );
    });
  }

  /**
   * @param log log status
   * @param isEndCall boolean Data
   * @description insert the call log
   */
  public InsertCallLog(log: any, isEndCall: boolean) {
    const promise = new Promise((resolve, reject) => {
      this.api
        .InsertCallLog(log)
        .toPromise()
        .then(
          (apiCallLog) => {
            // if (isEndCall) {
            //   this.GetCallLog(log.id);
            // }
            resolve(null);
          },
          (err) => {
            // Error
            reject(err);
          }
        );
    });
    return promise;
  }

  /**
   * @param applicant applicant
   * @description select the applicant
   */
  public SelectApplicant(applicant: Applicant) {
    // const app = JSON.parse(JSON.stringify(applicant));
    this.selectedApplicant = applicant;
    this.SelectedApplicant.next(applicant);
  }

  /**
   * @param applicationId selected application Id
   * @description fetch the call log's informaion
   */
  public GetCallLog(applicationId: string) {
    this.api.GetCallLog<any>(applicationId).subscribe((apiCallLogs) => {
      Object.freeze(apiCallLogs);
      if (
        typeof this.list === 'object' &&
        this.list !== null &&
        this.list[applicationId]
      ) {
        this.list[applicationId].callLogs = apiCallLogs;
      }
      this.CallLogs.next(apiCallLogs);
    });
  }

  public loadNotifications(applicationId: string) {
    this.api.getNotifications<any>(applicationId).subscribe((notifications) => {
      Object.freeze(notifications);
      if (
        typeof this.list === 'object' &&
        this.list !== null &&
        this.list[applicationId]
      ) {
        this.list[applicationId].notifications = notifications;
      }
      this.Notifications.next(notifications);
    });
  }

  public EncryptValue(value: StringToEncrypt): any {
    return this.api.EncryptValue<EncryptedValues>(value);
  }

  /**
   * @param applicationId selected application Id
   * @description Load the Recall application
   */
  public LoadRecall(applicationId) {
    this.api.GetRecallHistory<Recall>(applicationId).subscribe((res) => {
      if (res) {
        if (typeof this.list === 'object' && this.list !== null) {
          this.list[applicationId].recalls = res;
        }
        this.ApplicationRecalls.next(res);
      } else {
        if (typeof this.list === 'object' && this.list !== null) {
          this.list[applicationId].recalls = [];
        }

        this.ApplicationRecalls.next([]);
      }
    });
  }

  /**
   * @param appId selected app Id
   * @description fetching the all recall questions
   */
  getRecallQuestions(appId) {
    this.api.GetRecallHistory(appId).subscribe((res: any) => {
      if (res) {
        this.ApplicationRecalls.next(res);
      }
    });
  }

  /**
   * @description clean details questions
   */
  public CleanDetailQuestions() {
    this.DetailQuestions.next(null);
  }

  /**
   * @param search search
   * @description search the applicants
   */
  public SearchApplication(search: any): any {
    return new Promise<Array<ApplicationSearchResult>>((resolve, reject) => {
      this.api.SearchApplication<ApplicationSearchResult>(search).subscribe(
        (data) => resolve(data),
        (error) => reject(error)
      );
    });
  }

  public Clear(killCache = true) {
    const applicationId = this.selectedApplication
      ? this.selectedApplication.id
      : null;

    this.Reset(this.SelectedApplicant, 'selectedApplicant');
    this.Reset(
      this.SelectedApplication,
      'selectedApplication',
      Application.DefaultInstance()
    );

    this.Reset(this.ezAppApplication);
    this.Reset(this.OnlineApplicants);

    this.Reset(this.DashboardAnswers);

    this.Reset(this.Notes);
    this.Reset(this.CallLogs);
    this.Reset(this.Notifications);

    this.Reset(this.ApplicationAppointment);
    this.Reset(this.Answers);
    this.Reset(this.DetailQuestions);
    this.Reset(this.ApplicationRecalls);
    this.Reset(this.notesText);
    if (killCache && applicationId) {
      this.list[applicationId] = null;
    }
  }

  private Reset(
    subject: BehaviorSubject<any>,
    property?: any,
    defaultValue?: any
  ) {
    if (property) {
      this[property] = defaultValue ? defaultValue : null;
    }

    // subject.next(null);
    subject.next(defaultValue ? defaultValue : null);
  }
  public SetDayFromDate(date) {
    if (moment(date).isSame(new Date(), 'day')) {
      return `Today's`;
    }
    if (
      moment(date).isSame(new Date().setDate(new Date().getDate() + 1), 'day')
    ) {
      return `Tommorow's`;
    } else if (
      moment(date).isSame(new Date().setDate(new Date().getDate() - 1), 'day')
    ) {
      return `Yesterday's`;
    } else {
      const formattedDate = moment(date, 'YYYY/MM/DD');
      return `${formattedDate.format('MMM')} ${moment
        .localeData()
        .ordinal(+formattedDate.format('D'))} (${formattedDate.format(
        'dddd'
      )}) `;
    }
  }

  private IsAnswerValueConformsToType(value: any, type: string) {
    type = type || 'none';
    let conforms = true;

    switch (type.toString().toLowerCase()) {
      case 'applicantqualifying': {
        conforms = value === null || Array.isArray(value);
        break;
      }
      default: {
        conforms = true;
        break;
      }
    }

    if (!conforms) {
      console.log('Answer value did not conform to the propertytype');
      throw new Error('Mismatched value and type');
    }
  }
}

export interface AppointmentStatusUpdateRequest {
  StatusId: number;
  StopCallId: string;
  AppointmentID: string;
  ResourceID: number;
}

export class ApiAnswer {
  id: number;
  state: string;
  answers: CosmosAnswer[];
  etag: string;
}

export class ApplicationCacheItem {
  application: Application = Application.DefaultInstance();
  notes: ApplicationNote[] = [];
  callLogs: CallLog[] = [];
  answers: Answer[] = [];
  recalls: Recall[] = [];
  notifications: Notification[] = [];
  originalApplication: Application = Application.DefaultInstance();
  etagAnswers = '';
}

export class CacheListItem {
  [key: string]: ApplicationCacheItem;
}
