import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Applicant } from 'src/app/shared/models/application/applicant';
import { Contact } from 'src/app/shared/models/application/contact';
import { UserProfile } from 'src/app/shared/models/user-profile';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { UserProfileService } from 'src/app/shared/services/user/user-profile.service.service';
import { AddOnsService } from '../add-on/addon.service';
import {
  AddonApplicant,
  AddOnApplication,
  ApplicantActionType,
  ApplicantType,
  AddonApplicantAuditItem,
  CreateApplicationTrackerRecord,
} from '../add-on/addon-application';
import { UUID } from 'angular2-uuid';
import { ToastService } from 'src/app/shared/services/toast/toast.service';
import { Application } from 'src/app/shared/models/application/application';
import { ApplicationStatus, ApplicationTrackerType } from '../../models/enum';
import { AdditionalInfoService } from '../additional-info/additional-info.service';
import { ApplicationTracker } from '../additional-info/additional-info-modal';
import { VcallApiService } from 'src/app/shared/services/api/vcall/vcall-api.service';
import { NgForm, Validators } from '@angular/forms';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';
import { CstPipe } from 'src/app/shared/pipes/cst.pipe';
import { STATES } from 'src/app/shared/models/states';

@Component({
  selector: 'app-addon-applicant',
  templateUrl: './addon-applicant.component.html',
  styleUrls: ['./addon-applicant.component.scss'],
})
export class AddonApplicantComponent implements OnInit, OnChanges {
  @Input() openAddApplicantModal: boolean;
  @Output() closeApplicantModal = new EventEmitter();
  @ViewChild('applicantForm', { read: NgForm }) addApplicationForm!: NgForm;
  addPersonalDetails: Applicant = {} as Applicant;
  addApplicantList = new Array<AddonApplicant>();
  addContact: Contact;
  minDate: { year: number; month: number; day: number };
  subscriptions = new Array<Subscription>();
  profile: UserProfile;
  application: Application;
  selectedApplicant: AddOnApplication;
  addOnActionType: string = null;
  updateReason: string;
  applicationTracker: ApplicationTracker;
  applicantSave = false;
  domainUserName: string;
  constructor(
    private appService: ApplicationService,
    private addonService: AddOnsService,
    private profileService: UserProfileService,
    private toaster: ToastService,
    public service: AdditionalInfoService,
    private vcallApiService: VcallApiService,
    private cstPipe: CstPipe
  ) {
    this.minDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
    };
  }

  ngOnInit() {
    this.subscriptions.push(
      this.appService.SelectedApplication.subscribe((a) => {
        if (a && a !== null && a !== undefined) {
          this.addContact = { ...a.contact };
          this.application = a;
        }
      })
    );

    this.subscriptions.push(
      this.profileService.userProfile.subscribe((s) => {
        this.profile = s;
        this.domainUserName = s.domainUserName;
      })
    );

    this.subscriptions.push(
      this.service.orderRequests.subscribe((response) => {
        if (
          response !== null &&
          response['data'] &&
          response['data'].application
        ) {
          this.applicationTracker = response[
            'data'
          ].application.applicationTracker.find(
            (a) => a.applicationTrackerType === ApplicationTrackerType.Primary
          );
        }
      })
    );

    this.subscriptions.push(
      this.addonService.addOrEditApplicant.subscribe((res) => {
        if (res !== null) {
          this.openAddApplicantModal = true;
          this.selectedApplicant = res.applicant;
          this.addOnActionType = res.type;
          if (res.type === ApplicantActionType.editApplicant && res.applicant) {
            this.addPersonalDetails = res.applicant;
            this.addContact = { ...res.applicant.contact };
            this.addPersonalDetails.birthDate = this.patchBirthDate(
              res.applicant.birthDate
            ) as any;
          } else {
            this.setValidators();
            this.addContact = { ...this.application.contact };
          }
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.addContact = { ...this.application?.contact };
    }
  }

  constructAddOnApplication(applicant) {
    applicant.birthDate = this.verifyBirthDate(applicant);
    return {
      createdOn: this.cstPipe.transform(),
      createdBy: this.profile.name,
      appType: this.application.appType,
      appId: this.application.id,
      state: applicant.contact.state || this.application.state,
      type: ApplicantType.AddOnApplication,
      family: this.constructAddOnApplciant(applicant),
    };
  }

  constructAddApplicant(applicant) {
    applicant.birthDate = this.verifyBirthDate(applicant);
    return {
      id: this.selectedApplicant.id,
      createdOn: this.cstPipe.transform(),
      applicantType: ApplicantType.AddOn,
      appId: this.application.id,
      ...applicant,
    };
  }

  verifyBirthDate(applicant) {
    return typeof applicant.birthDate === 'object' &&
      applicant.birthDate !== null
      ? `${applicant.birthDate.month}/${applicant.birthDate.day}/${applicant.birthDate.year}`
      : applicant.birthDate;
  }

  patchBirthDate(date) {
    if (typeof date === 'string') {
      let d = date.split('/').map((v) => (v = this.pad(v)));
      return {
        year: parseInt(d[2]),
        month: parseInt(d[0]),
        day: parseInt(d[1]),
      };
    }
    return;
  }

  clearValidators() {
    this.addApplicationForm.controls.firstName.clearValidators();
    this.addApplicationForm.controls.firstName.updateValueAndValidity();
    this.addApplicationForm.controls.lastName.clearValidators();
    this.addApplicationForm.controls.lastName.updateValueAndValidity();
    this.addApplicationForm.controls.gender.clearValidators();
    this.addApplicationForm.controls.gender.updateValueAndValidity();
    this.addApplicationForm.controls.height.clearValidators();
    this.addApplicationForm.controls.height.updateValueAndValidity();
    this.addApplicationForm.controls.weight.clearValidators();
    this.addApplicationForm.controls.weight.updateValueAndValidity();
    this.addApplicationForm.controls.birthDate.clearValidators();
    this.addApplicationForm.controls.birthDate.updateValueAndValidity();
  }

  setValidators() {
    this.addApplicationForm.controls.firstName.setValidators([
      Validators.required,
      Validators.pattern(`^(?=.{1,40}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$`),
    ]);
    this.addApplicationForm.controls.firstName.updateValueAndValidity();
    this.addApplicationForm.controls.lastName.setValidators([
      Validators.required,
      Validators.pattern(`^(?=.{1,40}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$`),
    ]);
    this.addApplicationForm.controls.lastName.updateValueAndValidity();
    this.addApplicationForm.controls.gender.setValidators([
      Validators.required,
    ]);
    this.addApplicationForm.controls.gender.updateValueAndValidity();
    this.addApplicationForm.controls.height.setValidators([
      Validators.required,
    ]);
    this.addApplicationForm.controls.height.updateValueAndValidity();
    this.addApplicationForm.controls.weight.setValidators([
      Validators.required,
      Validators.pattern(`^[0-9]+$`),
    ]);
    this.addApplicationForm.controls.weight.updateValueAndValidity();
    this.addApplicationForm.controls.birthDate.setValidators([
      Validators.required,
    ]);
    this.addApplicationForm.controls.birthDate.updateValueAndValidity();
  }

  pad(n) {
    return n < 10 ? '0' + n : n;
  }

  titleCase(name, value) {
    this.addPersonalDetails[name] = value && value.toUpperCase();
  }

  constructUpdateAplicant(applicant) {
    applicant.birthDate = this.verifyBirthDate(applicant);
    const comments = [];
    const a = {} as AddonApplicantAuditItem;
    a.updatedBy = this.profile.name;
    a.updatedOn = this.cstPipe.transform();
    a.comment = this.updateReason;
    comments[0] = a;
    return {
      id: this.selectedApplicant.id,
      createdOn: this.cstPipe.transform(),
      applicantType: ApplicantType.AddOn,
      updates: comments,
      ...applicant,
    };
  }

  constructAddOnApplciant(a) {
    const guid = UUID.UUID();
    a.clientNo = guid;
    a.appId = this.application.id;
    a.applicantType = ApplicantType.AddOn;
    const mergeApplicant = { contact: a.contact, id: guid, ...a };
    return {
      [guid]: {
        ...mergeApplicant,
      },
    };
  }

  submit() {
    this.applicantSave = true;
    this.addPersonalDetails['contact'] = this.addContact;
    const applicant = {
      ...this.addPersonalDetails,
    } as AddonApplicant;
    if (this.addOnActionType === ApplicantActionType.addApplicant) {
      this.addonService
        .insertApplicant(
          this.selectedApplicant.id,
          this.constructAddApplicant(applicant)
        )
        .then((res) => {
          if (res && !res.hasOwnProperty('error')) {
            this.closeModal();
            this.fetchApplicants();
          }
        });
    } else if (this.addOnActionType === ApplicantActionType.editApplicant) {
      this.addonService
        .updateApplicant(
          this.selectedApplicant.addOnAppId,
          this.selectedApplicant.id,
          this.constructUpdateAplicant(applicant)
        )
        .then((res) => {
          if (res && !res.hasOwnProperty('error')) {
            this.closeModal();
            this.fetchApplicants();
          }
        });
    } else {
      this.addonService
        .addOnApplication(this.constructAddOnApplication(applicant))
        .then((res) => {
          if (res && !res.hasOwnProperty('error')) {
            this.service.applicationTrackerRecord(
              this.applicationTrackerRecord(res.id, applicant)
            );
            this.closeModal();
            this.fetchApplicants();
          }
        });
    }
  }

  fetchApplicants(appId?) {
    const id =
      this.application && this.application.id ? this.application.id : appId;
    setTimeout(() => {
      this.vcallApiService
        .GetApplication(id.toString())
        .subscribe(async (res) => {
          const a = Application.Copy(res);
          await this.appService.SelectedApplication.next(a);
        });
    }, 1000);
  }

  closeModal() {
    this.closeApplicantModal.emit(false);
    this.addPersonalDetails = {} as Applicant;
    this.addOnActionType = null;
    this.updateReason = null;
    this.openAddApplicantModal = false;
    this.applicantSave = false;
    this.clearValidators();
  }

  applicationTrackerRecord(addonAppId, applicant) {
    const record = {} as CreateApplicationTrackerRecord;
    const {
      id,
      primaryApplicant,
      product,
      policyInformation,
    } = this.application;
    record.applicationId = id;
    record.state = applicant.contact.state || this.application.state;
    record.applicationCreatedDate = this.cstPipe.transform();
    record.primaryContactName = this.fullName(primaryApplicant);
    record.agentName = this.fullName(this.application['agent']);
    record.product = product.description;
    record.controlNumber = policyInformation[0].controlNumber;
    record.applicationStatusId = ApplicationStatus['With UnderWriting Team'];
    record.assignedTo = this.addonService.formatString(this.domainUserName);
    record.createdBy = this.addonService.formatString(this.domainUserName);
    record.createdDate = this.cstPipe.transform();
    record.applicationTrackerType = ApplicationTrackerType.AddOnApplication;
    record.addOnApplicationId = addonAppId;
    return record;
  }

  fullName(a) {
    return `${a.lastName}, ${a.firstName}`;
  }

  getStates() {
    return STATES;
  }
}
