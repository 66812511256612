import { Injectable } from '@angular/core';
import { ProcessorModel } from '../processor-model';
import { Processor } from '../processor';
import { DemographicUpdateResponseItem, ProcessingResult } from '../processing-result';
import { UserProfileService } from '../../user/user-profile.service.service';
import * as _ from 'lodash';
import { VcallApiService } from '../../api/vcall/vcall-api.service';
import { ApplicationService } from '../../application/application.service';
import { LoggerService } from 'src/app/core/logger.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationChangeProcessor implements Processor {
  name = 'Notifying AS400';
  mustWaitForFinish: boolean;
  stopIfError: any;
  hasStopProcessing: boolean = false;

  constructor(
    private vcallService: VcallApiService,
    private userProfileService: UserProfileService,
    private applicationService: ApplicationService,
    private loggerService: LoggerService
  ) {}

  async Process(model: ProcessorModel): Promise<ProcessingResult> {
    let self = this;

    let userProfile = this.userProfileService.getUserProfile();
    let applicationId = model.application.id; 

    var payload = {
      applicationId: applicationId,
      user: userProfile.domainUserName,
      ApplicationURL: window.location.origin
    };    

    await self.vcallService.updateContact(payload)
                            .then((response: any) => {
                              self.applicationService.updateAnswerModel(response);
                            })
                            .catch((error) => {
                              model.logger.logException(error);
                            });

    return Promise.resolve({
      result: true,
      stopProcessing: false,
    });
  }
}
