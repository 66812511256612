import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  HostListener,
  DoCheck,
  OnDestroy,
} from '@angular/core';
import { BehaviorSubject, Observable, Subscriber, Subscription } from 'rxjs';
import { Question } from 'src/app/shared/models/vcall/question';
import { TemplateModel } from 'src/app/shared/models/vcall/templateModel';
import { VCallApplicant } from 'src/app/shared/models/vcall/vcallApplicants';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import {
  NavigationParameter,
  ScriptService,
} from 'src/app/shared/services/script/script.service';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';
import { UserProfileService } from 'src/app/shared/services/user/user-profile.service.service';
declare var $: any;
import {
  NavigationAwareQuestionType,
  NavigationDirection,
} from '../types/baseQuestionType';
import { ToastService } from 'src/app/shared/services/toast/toast.service';
import { NavigationAwareQuestionTypeSimple } from '../demographic-applicants/demographic-applicants.component';
import { AppointmentAnswerService } from 'src/app/shared/services/appointmentAnswer/appointmentAnswer.service';
import { EndCallModalComponent } from 'src/app/shared/components/end-call-modal/end-call-modal.component';
import { environment } from 'src/environments/environment';

export enum KEY_CODE {
  RIGHT_ARROW = 'ArrowRight',
  LEFT_ARROW = 'ArrowLeft',
}

@Component({
  selector: 'app-vcall-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css'],
})
export class VcallQuestionComponent implements OnInit, DoCheck, OnDestroy {
  constructor(
    public service: ScriptService,
    private agentService: UserProfileService,
    private applicationService: ApplicationService,
    private appointmentService: AppointmentService,
    private uiServiceService: UiServiceService,
    private toastService: ToastService,
    private appointmentAnswerService: AppointmentAnswerService
  ) {}

  question: Question = null;
  questionsLength = 0;
  questionText$ = new Subscriber<string>();
  questionTextO$ = new Observable<string>((o) => (this.questionText$ = o));
  questionText = '';
  model: TemplateModel = new TemplateModel();
  model$: BehaviorSubject<TemplateModel> = new BehaviorSubject<TemplateModel>(
    null
  );
  onlineApplicants: VCallApplicant[] = new Array<VCallApplicant>();
  hideEndCall = true;
  canGoNextFlag = false;
  navigation: NavigationParameter = null;
  otherSubscribed = false;
  subscriptions: Subscription[] = [];
  notesText: string;
  readonly complete = 'complete';
  ifInputFocus = false;
  environment = environment;
  @ViewChild('questionType') questionType: ElementRef;
  @ViewChild(EndCallModalComponent) endCall: EndCallModalComponent;

  // TODO : make this work with forkJoin to remove the ugliness
  ngOnInit() {
    this.subscriptions.push(
      this.applicationService.notesText.subscribe((data) => {
        this.notesText = data;
      })
    );
    this.service.SelectedQuestion.subscribe((q) => {
      if (q && Object.keys(q).length > 0) {
        this.question = q;
        // TODO: avoid redundant interpolation if not required.
        this.questionsLength = this.service.FindSectionQuestionsLength(
          this.question
        );

        this.questionText$.next(this.question.text);
        if (this.otherSubscribed === false) {
          this.subscribeToEntitiesPostQuestion();
          this.otherSubscribed = true;
        }

        if (this.question.type === 'Closing') {
          this.hideEndCall = false;
        } else {
          this.hideEndCall = true;
        }
        this.canGoNext();
      }
    });

    this.subscriptions.push(
      this.applicationService.OnlineApplicants.subscribe((applicants) => {
        this.onlineApplicants = applicants;
      })
    );

    this.subscriptions.push(
      this.service.Navigator.subscribe((n) => {
        this.navigation = n;
        this.canGoNext();
      })
    );
  }

  changeText(text) {
    setTimeout(() => {
      this.questionText$.next(text);
      this.service.UpdatePromptText(text);
    }, 0);
  }

  subscribeToEntitiesPostQuestion() {
    this.subscriptions.push(
      this.agentService.callZoneUser.subscribe((a) => {
        this.model.agent = a;
        if (a != null && this.question && this.question.text !== undefined) {
          const text = this.safeInterpolate(
            this.question.text,
            this,
            this.model,
            this.question
          );
          this.changeText(text);
        }
        // console.log('agent received ' + (a != null ? a.name : ''));
      })
    );
    this.subscriptions.push(
      this.applicationService.SelectedApplication.subscribe((a: any) => {
        this.model.application = a;
        // console.log('application received');
        if (a != null && this.question && this.question.text !== undefined) {
          const text = this.safeInterpolate(
            this.question.text,
            this,
            this.model,
            this.question
          );
          this.changeText(text);
        }
      })
    );

    this.subscriptions.push(
      this.appointmentService.SelectedAppointment.subscribe((a) => {
        this.model.appointment = a;
        // console.log('appointment received');
        if (a != null && this.question && this.question.text !== undefined) {
          const text = this.safeInterpolate(
            this.question.text,
            this,
            this.model,
            this.question
          );
          this.changeText(text);
        }
      })
    );

    this.subscriptions.push(
      this.applicationService.SelectedApplicant.subscribe((a) => {
        // console.log('applicant received');
        if (a != null && this.question && this.question.text !== undefined) {
          this.model.selectedApplicant = a;
          const text = this.safeInterpolate(
            this.question.text,
            this,
            this.model,
            this.question
          );
          this.changeText(text);
        }
      })
    );
    const appointmentStatus =
      this.appointmentAnswerService.getAppointmentStatus();
    if (appointmentStatus && appointmentStatus === this.complete) {
      this.uiServiceService.PermissionDenied(
        `${this.appointmentService.selectedAppointment.appointmentId} since appointment is completed`
      );
      return;
    }

    // On page load checking the call is completed or not
    this.appointmentService.callIsCompletedOrNot(
      this.model.application.id.toString()
    );
  }

  canGoNext() {
    this.ifInputFocus = false;
    const tooEarlyToDecide = this.question === null || this.navigation === null;

    if (tooEarlyToDecide) {
      this.canGoNextFlag = false;
      return;
    }

    const requiredAndAnswered = this.question.required
      ? this.navigation.answerValue !== null
      : true;
    const childComponentSaysOkToGoNext = this.navigation.canGoNext;
    // TODO: discuss to see whether it makes sense to keep the logic for alllowNextForValue
    // const valueMeetsConfiguredValue = this.question.allowNextForValue === null ? true : this.navigation.answerValue === null ? false : this.navigation.answerValue.toString() === this.question.allowNextForValue;

    this.canGoNextFlag = requiredAndAnswered && childComponentSaysOkToGoNext; // && valueMeetsConfiguredValue;
  }

  // Next and previous buttons with keyboard navigation
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    const elements = document.getElementsByClassName('carousel-next-arrow');
    const bodyElement = document.querySelector('body');
    if (bodyElement && !bodyElement.classList.contains('modal-open')) {
      if (
        elements.length > 0 &&
        elements[0].hasAttribute('disabled') !== true &&
        elements[0].className !== 'no-button'
      ) {
        if (event.key === KEY_CODE.RIGHT_ARROW) {
          if (!this.hideEndCall) {
            this.toastService.error('', 'Please click on next button');
            return;
          }
          if (
            document.activeElement.tagName !== 'INPUT' &&
            document.activeElement.tagName !== 'TEXTAREA'
          ) {
            this.goNext();
          } else {
            this.ifInputFocus = true;
          }
        }
      } else {
        if (event.key === KEY_CODE.RIGHT_ARROW) {
          this.toastService.error('', 'Please select an option to proceed');
        }
      }
      if (event.key === KEY_CODE.LEFT_ARROW) {
        if (
          document.activeElement.tagName !== 'INPUT' &&
          document.activeElement.tagName !== 'TEXTAREA'
        ) {
          this.goBack();
        } else {
          this.ifInputFocus = true;
        }
      }
    }
  }

  goNext() {
    // Lets check with the child component
    // This is breaking away from the redux paradigm but keeps the interaction simple
    if (
      this.questionType instanceof NavigationAwareQuestionType ||
      this.questionType instanceof NavigationAwareQuestionTypeSimple
    ) {
      const result = this.questionType.Navigating(NavigationDirection.Next);
      if (result.stopPropagation) {
        // if(this.questionType instanceof Disposable )
        // {
        //   this.questionType.ngOnDestroy();
        // }
        return;
      }
    }

    // TODO -
    // let detail = this.questionChild.getAnserModel();
    // let answer = new answer(detail);
    if (
      this.question.allowNextForValue === null ||
      this.question.allowNextForValue === '' ||
      this.question.allowNextForValue === this.navigation.answerValue
    ) {
      //
      // if(this.questionType instanceof Disposable )
      // {
      //   this.questionType.ngOnDestroy();
      // }
      //
      this.appointmentAnswerService.updateAppointmentStatus(
        this.question,
        'active'
      );
      this.service.GoToNextQuestion(this.question);
      this.hideCall();
    } else {
      this.endCall.openReasonModal(null);
    }
  }

  goBack() {
    if (this.questionType instanceof NavigationAwareQuestionType) {
      const result = this.questionType.Navigating(NavigationDirection.Previous);
      if (result.stopPropagation) {
        return;
      }
    }

    this.service.GoToPreviousQuestion(this.question);
    if (
      this.question.section.name === 'Closing' &&
      this.question.sequence !== this.questionsLength - 1
    ) {
      this.hideEndCall = true;
    }

    this.hideCall();
  }

  safeInterpolate(
    stringToInterpolate: any,
    scope: object,
    props: object,
    question?: object
  ) {
    if (!stringToInterpolate) {
      return;
    }

    let result = stringToInterpolate;
    try {
      result = this.uiServiceService.interpolate(
        stringToInterpolate,
        scope,
        props,
        question
      );
    } finally {
    }
    return result;
  }

  hideCall() {
    if (this.question.type === 'Closing') {
      this.hideEndCall = false;
    } else {
      this.hideEndCall = true;
    }
  }

  ngDoCheck() {
    $('[data-toggle="popover"]').popover();
    $('.popover').css({ minWidth: '320px' });
  }

  ngOnDestroy() {
    this.subscriptions.map((subscribe) => subscribe.unsubscribe());
  }
}
