export const config = {
  listOfServicesWithApis: [
    {
      nameOfTheService: 'callZoneApiService',
      listofApisWithPayload: [
        {
          apiName: 'GetCallZoneUser',
          payload: `HQ\\BhushanM`,
        },
        {
          apiName: 'GetAppointments',
          payload: {
            resourceId: 5256,
          },
        },
        {
          apiName: 'GetRecallReasons',
          payload: ``,
        },
        {
          apiName: 'CreateAppointment',
          payload: '',
        },
        {
          apiName: 'GetStopCallReasons',
          payload: '',
        },
      ],
    },
    {
      nameOfTheService: 'vcallApiService',
      listofApisWithPayload: [
        {
          apiName: 'GetApplication',
          payload: '1757000',
        },
        {
          apiName: 'GetAnswers',
          payload: '1757000',
        },
        {
          apiName: 'GetNotes',
          payload: '1757000',
        },
        {
          apiName: 'GetCallLog',
          payload: '1757000',
        },
        {
          apiName: 'GetRecallHistory',
          payload: '1757000',
        },
        {
          apiName: 'GetCallLog',
          payload: '1757000',
        },
        {
          apiName: 'GetApplicationExport',
          payload: '1757000',
        },
        {
          apiName: 'GetScript',
          payload: {
            applicationId: 1757000,
            appointmentId: null,
            sectionId: null,
            questionId: null,
            mode: null,
            stopNavigation: null,
          },
        },

        {
          apiName: 'GetDetailQuestions',
          payload: {
            applicationId: '1757000',
            AQTag: '1757000',
            QQTag: '1757000',
          },
        },
      ],
    },
  ],
};
